































































.q-toolbar
  min-height: 64px
  transition: background-color 250ms
  will-change: background-color
.q-input
  max-width 768px
.menuIcon
  position absolute
  z-index 10
  background-color white
  border-radius 100%
  font-size .25em
  line-height .25em
  padding 4px
  bottom -2px
  right -2px
