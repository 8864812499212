





















































.btn-switch
  margin-right: 16px
